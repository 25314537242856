
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonNote,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { reactive } from "@vue/reactivity";

import DatePicker from "@/components/inputs/DatePicker.vue";
import UserSelect from "@/components/selects/UserSelect.vue";
import { createCustomerDay } from "@/db";
import { dateToString } from "@/helpers";
import router from "@/router";
import store from "@/store";

export default {
  name: "Select Date",
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    UserSelect,
    IonButton,
    IonNote,
    IonCard,
    DatePicker,
  },
  props: {
    customerID: String,
  },
  setup(props: any) {
    const state = reactive({
      customerID: props.customerID == "new" ? "" : props.customerID,
      date: dateToString(new Date()),
    });

    const create = async () => {
      if (state.customerID && state.date) {
        await createCustomerDay(state.date, state.customerID);
        router.replace({
          name: "Customer Day",
          params: { userID: state.customerID, date: state.date },
        });
      } else throw Error("Please select an customer and date.");
    };

    return {
      router,
      state,
      store,
      create,
    };
  },
};
